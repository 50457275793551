import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Advent of Code 2018`}</h1>
    <p>{`Source code at `}<a parentName="p" {...{
        "href": "https://github.com/dashed/advent-of-code"
      }}>{`github.com/dashed/advent-of-code`}</a></p>
    <p>{`Solved the `}<a parentName="p" {...{
        "href": "https://adventofcode.com/2018"
      }}>{`Advent of Code 2018`}</a>{` puzzles using the Rust programming language.`}</p>
    <p>{`The gif below is the result after completing all the puzzles 👇`}</p>
    <br />
    <br />
    <hr />
    <br />
    <ImageSource src="advent-of-code-2018/advent_of_code_2018.gif" alt="Advent of Code 2018" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      